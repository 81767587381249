import { Container, Row, Col } from "react-bootstrap";
import locationImg from "../assets/img/IMMMU_gif2.gif"; // Asegúrate de tener una imagen de ubicación
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Location = () => {
  return (
    <section className="location" id="location">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={locationImg} alt="Location" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Ubicación</h2>
                  <h5>Monterrey 193, Roma Norte, CDMX.</h5>
                  
                  <div className="schedule-container">
                    <p>Horarios:</p> 
                    <p>Lunes a jueves de 2:00 a 8:40 <br /> 
                    Viernes a domingo de 12:00 a 8:40</p>
                    <p>Para conocer nuestros horarios en días festivos, consulta nuestras redes sociales.</p>
                  </div>
                </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col size={12} md={10}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.9686313312086!2d-99.1648712332718!3d19.413761274606443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff31a5c36eff%3A0xa9b9fc596c9488fd!2sIMMMU!5e0!3m2!1ses-419!2smx!4v1731364238831!5m2!1ses-419!2smx"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Maps"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
