import React from 'react';
import { Card } from 'react-bootstrap';

const EventDetails = ({ event }) => {
  return (
    <Card className="purchase-page-container">
      <Card.Img variant="top" src={event.eventImage} alt={event.title} />
      <Card.Body>
        <Card.Title>{event.title}</Card.Title>
        <Card.Text>{event.description}</Card.Text>
        <Card.Text><strong>Detalles:</strong> {event.details}</Card.Text>
        <Card.Text><strong>Información de boletos:</strong></Card.Text>
        <Card.Text><strong>Entrada General: $380 MXN </strong>{event.general}</Card.Text>
        <Card.Text><strong>Entrada VIP: $580 MXN </strong>{event.vip}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default EventDetails;
