import axios from 'axios';

// Configura la URL base de tu backend
const API = axios.create({
  baseURL: 'process.env.REACT_APP_API_URL/api',
});

const API_URL = process.env.REACT_APP_API_URL || 'process.env.REACT_APP_API_URL/api';

// Obtener horarios disponibles por fecha
export const getAvailableTimes = async (day) => {
  try {
    const response = await API.get(`/events/${day}/available-times`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener eventos:', error);
    throw error;
  }
};

// Realizar una compra
export const registerSale = async (purchaseData) => {
  try {
    const response = await API.post('/sales', purchaseData);
    return response.data;
  } catch (error) {
    console.error('Error al realizar la compra:', error);
    throw error;
  }
};

// Calcular el total
export const calculateTotal = async (ticketData) => {
  try {
    const response = await API.post(
      '/events/calculate-total',
      ticketData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al calcular el total:', error);
    throw error;
  }
};

