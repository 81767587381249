import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import DateSelector from './DateSelector';
import TimeSelector from './TimeSelector';
import TicketTypeSelector from './TicketTypeSelector';
import QuantitySelector from './QuantitySelector';
import { getAvailableTimes, calculateTotal } from '../services/api';

// Funciones de validación
const isValidDate = (date) => {
  return date && date.match(/^\d{4}-\d{2}-\d{2}$/);
};

const isValidTime = (time) => {
  return time && time.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/);
};

const isValidTicketType = (type) => {
  return ['general', 'vip'].includes(type); // Ajusta según tus tipos válidos
};

const isValidQuantity = (quantity) => {
  return Number.isInteger(quantity) && quantity > 0 && quantity <= 10; // Ajusta el máximo según tus necesidades
};

// Funciones de sanitización
const sanitizeDate = (date) => {
  return date.trim();
};

const sanitizeTime = (time) => {
  return time.trim();
};

const sanitizeString = (str) => {
  return str.trim().toLowerCase();
};

// Función para obtener el token CSRF
const getCsrfToken = () => {
  return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';
};

const TicketForm = ({ onSubmit, submitButtonText = 'Proceder al Pago' }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [eventTimes, setEventTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedTicketType, setSelectedTicketType] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0); // Estado para el total
  const [error, setError] = useState(''); // Nuevo: Manejo de errores
  const [isLoading, setIsLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || 'process.env.REACT_APP_API_URL';
  const [validationErrors, setValidationErrors] = useState({
    date: '',
    time: '',
    ticketType: '',
    quantity: ''
  });
  const [formError, setFormError] = useState(''); // Agregar este nuevo estado
  const [availableTickets, setAvailableTickets] = useState(10);

  useEffect(() => {
    const fetchEventTimes = async () => {
      if (selectedDate) {
        try {
          const times = await getAvailableTimes(selectedDate);
          
          // Imprimir la fecha seleccionada sin procesar
          console.log('Fecha seleccionada original:', selectedDate);
          
          // Obtener la fecha y hora actual
          const now = new Date();
          const selectedDateObj = new Date(selectedDate + 'T00:00:00'); // Forzar la zona horaria local
          
          console.log('Fechas sin procesar:', {
            fechaSeleccionadaOriginal: selectedDate,
            fechaActual: now.toISOString(),
            fechaSeleccionada: selectedDateObj.toISOString()
          });
          
          // Establecer ambas fechas al inicio del día para comparación correcta
          const todayStart = new Date(now);
          todayStart.setHours(0, 0, 0, 0);
          
          const selectedDateStart = new Date(selectedDateObj);
          selectedDateStart.setHours(0, 0, 0, 0);
          
          console.log('Fechas procesadas:', {
            fechaActualInicio: todayStart.toISOString(),
            fechaSeleccionadaInicio: selectedDateStart.toISOString(),
            sonIguales: todayStart.getTime() === selectedDateStart.getTime()
          });
          
          // Verificar si es el día actual
          const isToday = todayStart.getTime() === selectedDateStart.getTime();
          
          const filteredTimes = times.available_times.filter(time => {
            const [hours, minutes] = time.split(':');
            const eventTime = new Date(selectedDateObj);
            eventTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);
            
            // Tiempo mínimo (40 minutos después de ahora)
            const minAllowedTime = new Date(now.getTime() + (40 * 60 * 1000));
            
            let isValid;
            if (selectedDateStart < todayStart) {
              // Fecha pasada
              isValid = false;
            } else if (isToday) {
              // Día actual: solo horas futuras más 40 minutos
              isValid = eventTime > minAllowedTime;
            } else {
              // Fecha futura: todas las horas
              isValid = true;
            }
            
            console.log(`Evaluando ${time}:`, {
              horaEvento: eventTime.toISOString(),
              horaMinima: minAllowedTime.toISOString(),
              esHoy: isToday,
              esValida: isValid
            });
            
            return isValid;
          });
          
          setEventTimes(filteredTimes);
          setError(filteredTimes.length === 0 ? 'Selecciona un dia con horarios disponibles para reservar en este momento.' : '');
          // Restablecer selectedTime si ya no está disponible
          if (!filteredTimes.includes(selectedTime)) {
            setSelectedTime('');
            setValidationErrors(prev => ({
              ...prev,
              time: ''
            }));
          }

        } catch (error) {
          console.error('Error al obtener horarios disponibles:', error);
          setEventTimes([]);
          setSelectedTime('');
        }
      } else {
        setEventTimes([]);
        setSelectedTime('');
      }
    };

    fetchEventTimes();
  }, [selectedDate, selectedTime]);

   // Nuevo efecto para calcular el total
   useEffect(() => {
    const fetchTotal = async () => {
      if (selectedTicketType && quantity > 0 && selectedDate && selectedTime) {
        try {
          const response = await calculateTotal({ 
            date: selectedDate,
            time: selectedTime,
            type: selectedTicketType,
            quantity: parseInt(quantity),
          });
          setTotal(response.total);
          setAvailableTickets(response.availableTickets || 10);
          
          if (quantity > response.availableTickets) {
            setQuantity(response.availableTickets);
            setFormError(`Solo hay ${response.availableTickets} boletos disponibles para esta función.`);
          }
        } catch (error) {
          console.error('Error al calcular el total:', error);
          setError('Error al calcular el total. Por favor, intenta nuevamente.');
          setTotal(0);
        }
      } else {
        setTotal(0);
      }
    };

    fetchTotal();
  }, [selectedDate, selectedTime, selectedTicketType, quantity]);

   const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(''); // Restablecer la hora seleccionada al cambiar la fecha
    setValidationErrors(prev => ({
      ...prev,
      date: date ? '' : 'Por favor selecciona una fecha',
      //time: 'Por favor selecciona una hora' // Opcional: Forzar la validación de la hora
    }));
    setFormError('');
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    setValidationErrors(prev => ({
      ...prev,
      //time: time ? '' : 'Por favor selecciona una hora'
    }));
  };

  const handleTicketTypeChange = (type) => {
    setSelectedTicketType(type);
    setValidationErrors(prev => ({
      ...prev,
      ticketType: type ? '' : 'Por favor selecciona un tipo de ticket'
    }));
  };

  const handleQuantityChange = (qty) => {
    if (qty > availableTickets) {
      setQuantity(availableTickets);
      setFormError(`Solo hay ${availableTickets} boletos disponibles para esta función.`);
    } else {
      setQuantity(qty);
      setFormError('');
    }
    
    setValidationErrors(prev => ({
      ...prev,
      quantity: qty > 0 ? '' : 'Por favor selecciona la cantidad'
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setFormError('');
    try {
      // Validaciones iniciales
      const camposFaltantes = [];
      
      if (!selectedDate) camposFaltantes.push('fecha');
      if (!selectedTime) camposFaltantes.push('hora');
      if (!selectedTicketType) camposFaltantes.push('tipo de ticket');
      if (quantity < 1) camposFaltantes.push('cantidad');

      if (camposFaltantes.length > 0) {
        setFormError(`Por favor, completa los siguientes campos: ${camposFaltantes.join(', ')}`);
        setIsLoading(false);
        return;
      }

      // Crear el objeto para la sesión de pago con el formato correcto
      const checkoutData = {
        quantity: parseInt(quantity),
        date: selectedDate,
        time: selectedTime,
        type: selectedTicketType.toLowerCase()
      };

      // Crear el objeto completo para localStorage
      const purchaseData = {
        event_id: `immmu_event_${selectedDate.replace(/-/g, '')}`,
        event_date: selectedDate,
        event_time: selectedTime,
        ticket_type: selectedTicketType.toLowerCase(),
        quantity: parseInt(quantity),
        total_amount: total
      };

      // Guardar datos completos en localStorage
      localStorage.setItem('purchaseData', JSON.stringify(purchaseData));

      // Enviar datos para crear la sesión de pago
      const response = await fetch(`${API_URL}/api/payment/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(checkoutData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Error ${response.status}: ${response.statusText}`);
      }

      const { url } = await response.json();
      
      if (url) {
        window.location.assign(url);
      } else {
        throw new Error('No se recibió URL de redirección');
      }

    } catch (error) {
      console.error('Error detallado:', error);
      setFormError(error.message || 'Error al procesar el pago. Por favor, intenta nuevamente.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form>
      <h2 className="form-title">Reserva tus boletos</h2>
      
      {formError && (
        <div className="alert alert-danger mb-3" role="alert">
          <i className="bi bi-exclamation-triangle-fill me-2"></i>
          {formError}
        </div>
      )}
      
      <div className="mb-3">
        <DateSelector 
          value={selectedDate} 
          onChange={handleDateChange} 
        />
        {validationErrors.date && (
          <div className="text-danger small">{validationErrors.date}</div>
        )}
      </div>

      <div className="mb-3">
        <TimeSelector 
          times={eventTimes} 
          value={selectedTime} 
          onChange={handleTimeChange} 
        />
        {validationErrors.time && (
          <div className="text-danger small">{validationErrors.time}</div>
        )}
        {error && (
          <div className="text-danger small">{error}</div>
        )}
      </div>

      <div className="mb-3">
        <TicketTypeSelector 
          value={selectedTicketType} 
          onChange={handleTicketTypeChange} 
        />
        {validationErrors.ticketType && (
          <div className="text-danger small">{validationErrors.ticketType}</div>
        )}
      </div>

      <div className="mb-3">
        <QuantitySelector 
          value={quantity} 
          onChange={handleQuantityChange} 
        />
        {validationErrors.quantity && (
          <div className="text-danger small">{validationErrors.quantity}</div>
        )}
      </div>

      <p>Total: ${total}</p>
      <Button 
        variant="primary" 
        onClick={handleSubmit} 
        disabled={isLoading || eventTimes.lenght ===0} //Deshabilita si no hay tiempo disponible
      >
        {isLoading ? 'Procesando...' : submitButtonText}
      </Button>
    </Form>
  );
};

export default TicketForm;
