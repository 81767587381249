import { useNavigate } from 'react-router-dom';

const Cancel = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="row min-vh-100 align-items-center justify-content-center">
        <div className="col-12 col-md-6 text-center">
          <h1 className="display-4 mb-4">Pago Cancelado</h1>
          <p className="lead mb-4">Tu pago ha sido cancelado.</p>
          <button 
            onClick={() => navigate('/')}
            className="btn btn-primary"
          >
            Volver al inicio
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cancel;
