import { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import logo from '../assets/img/header-img.png';
import navIcon1 from '../assets/img/facebook.svg';
import navIcon2 from '../assets/img/instagram.svg';
import navIcon3 from '../assets/img/tiktok.svg';
import { Link, useLocation } from 'react-router-dom';

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, seScrolled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                seScrolled(true);
            } else {
                seScrolled(false);
            }
    }

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }    

    const handleNavClick = (sectionId) => {
        if (window.location.pathname !== '/') {
            // Si no estamos en la página principal, primero navegamos a ella
            window.location.href = '/' + sectionId;
        } else {
            // Si estamos en la página principal, hacemos scroll al elemento
            const element = document.getElementById(sectionId.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    return (
        <Navbar expand="lg" className={seScrolled ? "scrolled":""}>
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img src={logo} alt="Logo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"> 
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Inicio</Nav.Link>
                        <Nav.Link as={Link} to="/#categories" className={activeLink === 'categories' ? 'active navbar-link' : 'navbar-link'} onClick={() => {
                            onUpdateActiveLink('categories');
                            handleNavClick('#categories');
                        }}>Reseñas</Nav.Link>
                        <Nav.Link as={Link} to="/#location" className={activeLink === 'categories' ? 'active navbar-link' : 'navbar-link'} onClick={() => {
                            onUpdateActiveLink('location');
                            handleNavClick('#location');
                        }}>Ubicación</Nav.Link>
                    </Nav> 
                    <span className="navbar-text">
                        <div className="social-icon">
                            <a href="https://www.facebook.com/p/IMMMU-MX-61554412409417/?_rdr"><img  src={navIcon1} alt="" /></a>
                            <a href="https://www.instagram.com/_immmu_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><img  src={navIcon2} alt="" /></a>
                            <a href="https://www.tiktok.com/@_immmu_?is_from_webapp=1&sender_device=pc"><img  src={navIcon3} alt="" /></a>
                        </div>
                        {location.pathname === '/' && (
                            <Link to="/purchase" className="vvd">
                                <button>
                                    <span>Compra tus boletos</span>
                                </button>
                            </Link>
                        )}
                    </span>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}