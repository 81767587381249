import React, { useState } from 'react';
import TicketSelection from './TicketSelection';
import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

//const stripePromise = loadStripe('pk_live_51QUJ8kATpSfJ7e1LfD7n8PoOcJkzf3c6DCkeoYms9epcplDF5FBOpw3HCpCEXfA7XFsZaudtss9nWcB3scNjEggE00qUBdUIKA');
const stripePromise = loadStripe('pk_test_51QUJ8kATpSfJ7e1LE8DOxzEOqCpLTRZlMkQ5QRgfrbGOylokWI9tRwwv0gF1ude6bMn35gpttqAiEFAcYvRiI76600dwbWaPS6');
//const stripePromise = loadStripe('pk_test_51Q7VRJP4l78aOUuh3pP1Y5RGn6BEDGrUvob8mGA5RlyiR6HwZkgfipdiyOPcE3rLa3JYqxhI91jhTa9EIdZPrLp8000jARhDDJ'); anyelifugaz@fugaz.com

const PurchasePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handlePurchase = async (data) => {
    setIsLoading(true);
    setError(null);
    
    try {
      console.log('Iniciando proceso de pago con datos:', data);
      
      const response = await fetch('process.env.REACT_APP_API_URL/api/payment/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error en el proceso de pago');
      }

      const result = await response.json();
      
      if (result.url) {
        window.location.href = result.url;
      } else {
        throw new Error('No se recibió la URL de pago');
      }

    } catch (error) {
      setError(error.message);
      console.error('Error detallado:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="purchase-container">
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
      
      {isLoading ? (
        <div className="loading-spinner">Procesando su compra...</div>
      ) : (
        <TicketSelection onPurchase={handlePurchase} />
      )}
    </div>
  );
};

export default PurchasePage;
