import React from 'react';
import { Form } from 'react-bootstrap';

const QuantitySelector = ({ value, onChange }) => (
  <Form.Group className="mb-3">
    <Form.Label>Cantidad de Boletos</Form.Label>
    <Form.Control type="number" min="1" max="10" value={value} onChange={(e) => onChange(e.target.value)} />
  </Form.Group>
);

export default QuantitySelector;
