import React from 'react';
import { Form } from 'react-bootstrap';

const TicketTypeSelector = ({ value, onChange }) => (
  <Form.Group className="mb-3">
    <Form.Label>Tipo de Boleto</Form.Label>
    <Form.Select value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="">Selecciona un tipo de boleto</option>
      <option value="general">General - $380</option>
      <option value="vip">VIP - $580</option>
    </Form.Select>
  </Form.Group>
);

export default TicketTypeSelector;
