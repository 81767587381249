import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/header-img.png";
import navIcon1 from "../assets/img/facebook.svg";
import navIcon2 from "../assets/img/instagram.svg";
import navIcon3 from "../assets/img/tiktok.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.facebook.com/p/IMMMU-MX-61554412409417/?_rdr"><img src={navIcon1} alt="Facebook Icon" /></a>
              <a href="https://www.instagram.com/_immmu_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><img src={navIcon2} alt="Instagram Icon" /></a>
              <a href="https://www.tiktok.com/@_immmu_?is_from_webapp=1&sender_device=pc"><img src={navIcon3} alt="TikTok Icon" /></a>
            </div>
              <p>
                <strong>¿Necesitas asistencia?</strong>  <br></br>Contáctanos en{" "}
                <a href="mailto:contacto@immmu.mx">contacto@immmu.mx</a>
              </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
