import React from 'react';
import { Form } from 'react-bootstrap';

const DateSelector = ({ value, onChange }) => {
  const today = new Date().toISOString().split('T')[0];

  return (
    <Form.Group className="mb-3">
      <Form.Label>Fecha del Evento</Form.Label>
      <Form.Control type="date" min={today} value={value} onChange={(e) => onChange(e.target.value)} />
    </Form.Group>
  );
};

export default DateSelector;
