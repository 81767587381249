import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PurchaseSummary from './PurchaseSummary';

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const session_id = new URLSearchParams(location.search).get('session_id');
  const hasFetched = useRef(false);
  const [buyerDetails, setBuyerDetails] = useState(null);
  const [saleId, setSaleId] = useState(null);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        if (!session_id || hasFetched.current) return;
        hasFetched.current = true;
  
        const stripeResponse = await fetch(`process.env.REACT_APP_API_URL/api/payment/session/${session_id}`);
        const { session } = await stripeResponse.json();
  
        setBuyerDetails({
          buyer_name: session.customer_details.name,
          buyer_email: session.customer_details.email,
          stripe_payment_id: session_id
        });
  
        const purchaseData = JSON.parse(localStorage.getItem('purchaseData') || '{}');
  
        const saleData = {
          date: purchaseData.event_date,
          time: purchaseData.event_time,
          type: purchaseData.ticket_type,
          quantity: parseInt(purchaseData.quantity),
          buyer_info: {
            name: session.customer_details.name,
            email: session.customer_details.email
          },
          stripe_payment_id: session_id,
          total_amount: parseFloat(purchaseData.total_amount)
        };
  
        // Crear la venta
        const saleResponse = await fetch('process.env.REACT_APP_API_URL/api/sales', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(saleData)
        });
  
        if (!saleResponse.ok) {
          const saleError = await saleResponse.json();
          throw new Error(saleError.message || 'Error al registrar la venta.');
        }
  
        console.log('Venta creada exitosamente.');
  
        // Buscar el sale_id usando stripe_payment_id
        const saleIdResponse = await fetch(`process.env.REACT_APP_API_URL/api/sales/stripe/${session_id}`);
        if (!saleIdResponse.ok) {
          throw new Error('Error al obtener el sale_id.');
        }
  
        const saleIdData = await saleIdResponse.json();
        setSaleId(saleIdData.sale_id); // Guardar el sale_id en el estado
        console.log('Sale ID encontrado:', saleIdData.sale_id);
  
        const updateTicketsData = {
          event_date: purchaseData.event_date,
          event_time: purchaseData.event_time,
          ticket_type: purchaseData.ticket_type.toLowerCase(),
          quantity: parseInt(purchaseData.quantity)
        };
  
        const updateTicketsResponse = await fetch('process.env.REACT_APP_API_URL/api/events/update-tickets', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updateTicketsData)
        });
  
        if (!updateTicketsResponse.ok) {
          const updateError = await updateTicketsResponse.json();
          throw new Error(updateError.message || 'Error al actualizar los boletos.');
        }
  
        console.log('Venta registrada y boletos actualizados correctamente.');
        localStorage.removeItem('purchaseData');
      } catch (error) {
        console.error('Error al procesar los datos:', error);
      }
    };
  
    fetchSessionData();
  }, [session_id]);
  

  const purchaseData = {
    ...JSON.parse(localStorage.getItem('purchaseData') || '{}'),
    ...(buyerDetails || {})
  };


  return (
    <div className="success-container">
      <div className="success-card">
        <h1 className="success-title">¡Pago Exitoso!</h1>
        <div className="mb-4">
          <i className="bi bi-check-circle-fill success-icon"></i>
        </div>
        <p className="success-message">¡Gracias por comprar tus boletos!</p>
        <p className="success-message">Tu compra ha sido procesada exitosamente.</p>
  
        {/* Pasar el saleId al componente PurchaseSummary */}
        {saleId && <PurchaseSummary saleId={saleId} />}
  
        <div className="d-grid gap-3 col-md-8 mx-auto">
          <button
            onClick={() => navigate('/')}
            className="buy-button"
          >
            Volver al inicio
          </button>
        </div>
      </div>
    </div>
  );  
};

export default Success;
