import category1 from "../assets/img/logo-home-forma.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import review7 from "../assets/img/IMG_7.png"
import review6 from "../assets/img/IMG_6.png"
import review5 from "../assets/img/IMG_5.png"
import review4 from "../assets/img/IMG_4.png"
import review3 from "../assets/img/IMG_3.png"
import colorSharp from "../assets/img/color-sharp.png"

export const Categories = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="category" id="categories">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="category-bx wow zoomIn">
                        <h2 className="title">Reseñas</h2>
                        <p className="category-subtitle">Ellos ya vivieron la experiencia<br></br>Lee lo que otros dicen sobre Immmu y prepárate para sumergirte</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme category-slider">
                          <div className="item">
                                <img src={review7} alt="Image" />
                                <p>
                                    Nos encantó la experiencia y también vale mucho la pena la degustación ya que pruebas todos los helados y eliges cuál te gusta más.
                                </p>
                                <p>
                                    Tienen diferentes cuartos todos distintos pero todos muy padres. Nuestro favorito fue el de alberca de pelotas.
                                </p>
                                <p>Comida: 5/5 | Servicio: 5/5 | Ambiente: 5/5</p>
                            </div>
                            <div className="item">
                                <img src={review6} alt="Image" />
                                <p>
                                    Un lugar bien chévere para sanar y pasarla diferente contigo mismo o con tus personas favoritas. Lo más bonito es el trasfondo de cada espacio que presentan y la empatía del personal que te acompaña.
                                </p>
                                <p>
                                    Helados deliciosos y mágicos. Menta con habanero, ¡Wow! Amamos. 10/10.
                                </p>
                                <p>Comida: 5/5 | Servicio: 5/5 | Ambiente: 5/5</p>
                            </div>
                            <div className="item">
                                <img src={review5} alt="Image" />
                                <p>
                                    Una experiencia diferente en la CDMX que te lleva a explorar la nostalgia de ser niño. Con 10 salas increíbles e interactivas. Aparte de que los helados son espectaculares ya que son sabores que no encuentras en cualquier heladería, ya que van con la temática y experiencia de las salas.
                                </p>
                                <p>Recomendable para una date. 🤟🏻</p>
                                <p>Comida: 5/5 | Servicio: 5/5 | Ambiente: 5/5</p>
                            </div>
                            <div className="item">
                                <img src={review4} alt="Image" />
                                <p>
                                    Es una experiencia INCREÍBLE!! Disfruté muchísimo de cada una de sus salas y salí con una sonrisa de oreja a oreja. Por un lado, recordé sensaciones de mi niñez, lo cual estuvo bellísimo y súper divertido. Por el otro, fue una de las experiencias inmersivas más bonitas y divertidas que he tenido.
                                </p>
                                <p>
                                    P.D.: los helados están tan buenos como la experiencia y me comería tres antes y después del recorrido 😋.
                                </p>
                                <p>Comida: 5/5 | Servicio: 5/5 | Ambiente: 5/5</p>
                            </div>
                            <div className="item">
                                <img src={review3} alt="Image" />
                                <p>
                                    <strong>MILENIO:</strong> En un mundo donde la cotidianidad a menudo limita la expresión de nuestros sueños más oníricos, IMMMU emerge como un faro de esperanza, una puerta a dimensiones desconocidas donde la fantasía cobra vida y los límites se desdibujan. Este enclave de maravillas, enclavado en el corazón de la Ciudad de México, te invita a una experiencia sin igual.
                                </p>
                                <a href="https://www.milenio.com/content/immmu-la-mejor-experiencia-inmersiva-en-la-ciudad-de-mexico" target="_blank">Leer más</a>
                          </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}