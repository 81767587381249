import logo from './assets/img/logo-home-forma.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { Categories } from './components/Categories';
import { Events } from "./components/Events";
import { Location } from './components/Location';
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importa React Router
import PurchasePage from './components/PurchasePage';
import Success from './components/Success';
import Cancel from './components/Cancel';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" element={
            <>
              <Banner />
              <Categories />
              {/* <Events />  */}
              {/* <Contact /> */}
              <Location/>
            </>
          } />
          <Route path="/purchase" element={<PurchasePage />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
