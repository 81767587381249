import React from 'react';
import TicketForm from './TicketForm';
import EventDetails from './EventDetails';
import eventImage from "../assets/img/IMMMU_banner.jpg";

export const TicketSelection = ({ onPurchase }) => {
  const event = {
    title: "IMMMU",
    description: "Es una experiencia inmersiva en la Ciudad de México pensada para hacerte sentir como niño otra vez. Al recorrer diez salas temáticas, cada espacio te invita a jugar, explorar y redescubrir esa magia y creatividad que marcó tu infancia. Encontrarás desde una piscina de pelotas hasta un bosque de flores gigantes lleno de luces y efectos visuales. Cada rincón está diseñado para que vivas momentos únicos y captures fotos que atesores.",
    details: "La experiencia dura alrededor de 80 minutos y tiene varios recorridos a lo largo del día, creando una pausa divertida en la rutina y un escape a un mundo lleno de color, fantasía y sorpresa.",
    general:"Disfruta un recorrido guiado por las 10 salas de IMMMU, con asistencia personalizada para capturar tus fotos en cada espacio.",
    vip:"Incluye el recorrido guiado por las 10 salas, con apoyo para tus fotografías. Al final, podrás disfrutar de una degustación de los helados disponibles para elegir tu favorito y deleitarte con un cono de cortesía.",
    eventImage: eventImage,
  };

  {/*CREO QUE ESTO NO VA/*/}
  const handleSubmit = (selectedQuantity) => {
    onPurchase({
      quantity: selectedQuantity,
      priceId: 'price_XXXXXXXXXXXXXX'
    });
  };

  return (
    <div className="ticket-container">
      <div className="event-details-container">
        <EventDetails event={event} />
      </div>
      <div className="ticket-form-container">
        <TicketForm onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default TicketSelection;
