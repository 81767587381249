import React from 'react';
import { Form } from 'react-bootstrap';

const TimeSelector = ({ times = [], value, onChange }) => {
  // Verifica si el prop `times` es un array
  console.log('Horarios recibidos por TimeSelector:', times);

  if (!Array.isArray(times) || times.length === 0) {
    return <p>No hay horarios disponibles para la fecha seleccionada.</p>;
  }

  return (
    <Form.Group className="mb-3">
      <Form.Label>Horario del Evento</Form.Label>
      <Form.Control as="select" value={value} onChange={(e) => onChange(e.target.value)}>
        <option value="">Seleccione un horario</option>
        {times.map((time, index) => (
          <option key={index} value={time}>
            {time}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default TimeSelector;
